.Paintings {
  column-gap: 20px; /* 设置列间距 */
  width: calc(100% - 40px);
  max-width: 100vw;
  padding: 20px; /* 添加内边距 */
  background-color: #282c34;
}

.Painting {
  break-inside: avoid;
  margin-bottom: 20px; /* 设置图片之间的间距 */
  border-radius: 15px; /* 设置圆角 */
  overflow: hidden; /* 防止图片超出边界 */
  box-shadow: 0px 0px 10px #fff0; /* 添加阴影 */
}

.Painting img {
  width: 100%;
  height: auto;
  border-radius: 15px; /* 设置圆角，与父容器一致 */
  object-fit: cover; /* 确保图片以覆盖方式显示，保持比例 */
}
