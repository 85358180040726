

.Header{
    /* Sizing */
    height: 10vh;
    background-color: #282c34;

    /* Child Arrangement */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.HeaderItem{
    /* Positioning */
    margin: 0.5vw;
    margin-top: 3vh;

    /* Sizing */
    height: 5vh;
    background-color: #434a56;
    width: 8vw;
    border-radius: 1000px;

    /* Child Arrangement */
    display: flex;
    align-items: center;
    justify-content: center;
}

.HeaderItem:hover{
    background-color: #586070;
    cursor: pointer;
}

.HeaderItem span{
    color: rgb(223, 223, 223);
    font-size: 2vh;
    font-weight: bold;
    margin-bottom: 0.2vh;
}